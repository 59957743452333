import React from 'react'
import Page from '../Page/Page'

function AboutPage() {
  return (
    <Page name="AboutPage">
      <h1>About</h1>
    </Page>
  )
}

export default AboutPage
